<template>
  <v-card-text>
    <p class="mb-6 text-center grey--text text--darken-2 text-h3">Invitation</p>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="item.email"
        color="secondary"
        label="Email"
        disabled
        required
        rounded
        dense
      />
      <v-text-field
        v-model="form.name"
        :rules="nameRules"
        color="secondary"
        label="Name"
        outlined
        dense
        required
      />
      <v-text-field
        v-model="form.password"
        color="secondary"
        label="Password"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show ? 'text' : 'password'"
        :rules="passwordRules"
        outlined
        dense
        required
        @click:append.prevent="show = !show"
      />

      <v-text-field
        v-model="form.re_password"
        color="secondary"
        label="Confirm Password"
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show2 ? 'text' : 'password'"
        :rules="passwordRules"
        outlined
        dense
        required
        @click:append.prevent="show2 = !show2"
      />
      <v-btn
        :loading="loading"
        block
        :disabled="!valid"
        color="primary"
        rounded
        @click="formSubmit"
      >
        Register Account
      </v-btn>
      <p class="mt-5 text-center">
        <router-link to="/user/login" class="text-decoration-none">
          Login
        </router-link>
      </p>
    </v-form>
  </v-card-text>
</template>

<script>
import { baseApi } from '@/plugins/axios';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'UserInvitation',
  data() {
    return {
      loading: false,
      valid: true,
      form: {
        name: null,
        re_password: null,
        password: null,
      },
      showPassword: false,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length >= 4) || 'Min 4 characters',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => (v && v.length >= 8) || 'Min 8 characters',
        (confirmation) =>
          confirmation === this.form.password || 'Passwords must match',
      ],
      show: false,
      show2: false,
      docId: null,
      item: {},
    };
  },
  computed: {
    ...mapState({
      claims: (state) => state.user.claims,
    }),
  },
  watch: {
    docId: function (val) {
      if (val != undefined && val != null) {
        this.checkToken();
      } else {
        this.$store.dispatch('notification/add', {
          message: 'Your token has been expired',
          type: 'error',
        });
        this.$router.push('/user/login');
      }
    },
  },
  mounted() {
    this.docId = this.$route.params.docId;
    this.singOut();
  },
  methods: {
    ...mapActions('user', ['singOut', 'signInWithEmailAndPassword']),
    async checkToken() {
      try {
        let res = await baseApi.post('/users/invitations/check', {
          id: this.docId,
        });
        this.item = res.data;
        if (res.data == undefined) {
          this.$store.dispatch('notification/add', {
            message: 'Your token has been expired',
            type: 'error',
          });
        }
      } catch (err) {
        this.$store.dispatch('notification/add', {
          message: 'Your token has been expired',
          type: 'error',
        });
        this.$router.push('/user/login');
      }
    },
    async formSubmit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          let obj = {
            id: this.docId,
            name: this.form.name,
            password: this.form.password,
          };

          await baseApi.post('/users/invitations/users', obj);
          this.$store.dispatch('notification/add', {
            type: 'success',
            message: 'Your new user account has been registered.',
          });
          this.$router
            .replace({ path: `/user/login?email=${this.item.email}` })
            .catch((err) => {});
          this.loading = false;
        } catch (err) {
          this.loading = false;
          this.$store.dispatch('notification/add', {
            type: 'error',
            message: `An error occurred : ${err.message}`,
          });
        }
      }
    },
  },
};
</script>
